import React, { useLayoutEffect, useMemo } from 'react';
import { useEditController } from 'react-admin';
import _ from 'lodash';

import { parseAnswersFromRecord, parseQuestionsNodeInput, typeDictionary } from '../../lib/utils/surveys';
import FormUi from './FormUi';

const FormEdit = (props) => {
  const {
    setRecordQuestions,
    setStatus,
    setRandom,
    setSurveyName,
    status,
    surveyName,
    random,
    recordQuestions,
    setInput,
  } = props;
  const { record } = useEditController(props);

  const parsedInput = useMemo(() => ({
      id: record?.id,
      input: {
        active: status,
        name: surveyName,
        options: {
          randomEnabled: random,
        },
        questions: parseQuestionsNodeInput(recordQuestions, random),
      },
    }
  ), [record, status, surveyName, random, recordQuestions]);

  useLayoutEffect(() => {
    setInput(parsedInput);
    /* eslint-disable-next-line */
  }, [parsedInput]);

  useLayoutEffect(() => {
    if (record) {
      const {
        active, name, options, questions,
      } = record;

      setSurveyName(name);
      setStatus(active);
      setRandom(options?.randomEnabled);

      if (questions) {
        const parsed = [];
        questions.map((question) => {
          const parsedQuestion = _.omit(question, '__typename');
          if (typeof parsedQuestion === 'object') {
            /* eslint-disable */
            for (const [key, value] of Object.entries(parsedQuestion)) {
              if (value !== null) {
                const iType = typeDictionary(key);
                parsed.push({
                  ...value,
                  iType,
                  answersAvailable: parseAnswersFromRecord(iType, value?.answersAvailable),
                });
              }
            }
            /* eslint-enable */
          }

          return true;
        });
        setRecordQuestions(parsed);
      }
    }
    /* eslint-disable-next-line */
  }, [record]);

  return (<FormUi {...props} />);
};

export default FormEdit;
