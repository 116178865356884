import React, { useEffect, useState } from 'react';

import {
  useTranslate,
  ReferenceManyField,
  TextField,
  DateField,
  Pagination,
  Filter,
  TextInput,
  useListController,
  TopToolbar,
  ExportButton,
  ListContextProvider,
  Datagrid,
  FunctionField,
  CheckboxGroupInput, Loading,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { EmptyList } from '../../lib/components';
import exporter from './OrderExporter';
import OrderReferenceExpand from './OrderReferenceExpand';
import StatusSelect from './StatusSelect';
import {
  EVALUATED, CANCELLED, REDEEMED, REQUESTED, EXPIRED, ORDER_STATUS_FILTER,
} from '../promotions/constants';
import client from '../../providers/client';

const ZERO = 0;
const ONE = 1;
const TWO = 2;

const useStyles = makeStyles(
  theme => ({
    content: {
      paddingTop: theme.spacing(ONE),
      paddingLeft: theme.spacing(TWO),
      paddingRight: theme.spacing(TWO),
    },
    link: {
      textDecoration: 'none',
      color: 'blue',
      width: '100%',
      display: 'inline-block',
    },
    toolbar: {
      alignItems: 'flex-start',
      paddingRight: ZERO,
      justifyContent: 'space-between',
    },
  }),
  { name: 'OrderReferenceList' },
);

const LinkTextField = ({
  basePath, record = {}, source, addLabel, ...rest
}) => {
  const classes = useStyles();
  const to = `/promotions/${record?.promotion?.key}`;
  return (
    <TextField
      className={classes.link}
      source={source}
      record={record}
      component={Link}
      to={to}
      {...rest}
    />
  );
};

let filterSet = {
  user: {
    op: 'IN',
    value: [],
  },
  status: {
    op: 'IN',
    value: [],
  },
};

const OrderList = (props) => {
  const { currentFilter, id: userId } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const listContext = useListController(props);
  const { loading, setFilters, displayedFilters } = listContext;
  const [ordersPendingCompletion, setOrdersPendingCompletion] = useState('-');

  const GET_PENDING_ORDERS = gql`
    query amountOfOrdersPendingCompletionByUser($userId: ID!) {
      amountOfOrdersPendingCompletionByUser(userId: $userId) {
        amount
      }
    }
  `;

  const { data: dataPendingOrders } = useQuery(GET_PENDING_ORDERS, {
    variables: {
      userId,
    },
    client,
  });

  useEffect(() => {
    if (dataPendingOrders !== undefined) {
      const { amountOfOrdersPendingCompletionByUser } = dataPendingOrders;
      const { amount } = amountOfOrdersPendingCompletionByUser;
      setOrdersPendingCompletion(amount);
    }
  }, [dataPendingOrders]);

  const filterValues = {
    ...listContext.filterValues,
    ...currentFilter,
  };

  const getLatestDate = (statusHistory, source) => {
    let filterSource;
    if (source === 'requestedAt') {
      filterSource = REQUESTED;
    } else if (source === 'expiredAt') {
      filterSource = EXPIRED;
    } else if (source === 'redeemedAt') {
      filterSource = REDEEMED;
    } else if (source === 'cancelledAt') {
      filterSource = CANCELLED;
    } else if (source === 'evaluatedAt') {
      filterSource = EVALUATED;
    }

    const latest = statusHistory
      ?.filter(item => item.status === filterSource)
      ?.reduce((a, b) => (new Date(a.statusDate) > new Date(b.statusDate) ? a : b), []);
    return latest?.statusDate;
  };

  const getModifiedData = (newData) => {
    const modifiedData = {};
    Object.keys(newData).forEach((key) => {
      modifiedData[key] = { ...newData[key] };
      modifiedData[key].requestedAt = getLatestDate(modifiedData[key].statusHistory, 'requestedAt');
      modifiedData[key].expiredAt = getLatestDate(modifiedData[key].statusHistory, 'expiredAt');
      modifiedData[key].redeemedAt = getLatestDate(modifiedData[key].statusHistory, 'redeemedAt');
      modifiedData[key].cancelledAt = getLatestDate(modifiedData[key].statusHistory, 'cancelledAt');
      modifiedData[key].evaluatedAt = getLatestDate(modifiedData[key].statusHistory, 'evaluatedAt');
    });
    return modifiedData;
  };

  const newListContext = { ...listContext, data: getModifiedData(listContext.data) };

  const onChangeFilter = (e) => {
    const parsed = {
      ...e,
      status: {
        op: 'IN',
        ...e?.status,
      },
    };
    filterSet = parsed;
    setFilters(parsed, displayedFilters);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <ListContextProvider value={{ ...newListContext, filterValues }}>
      <TopToolbar className={classes.toolbar}>
        <Filter setFilters={onChangeFilter}>
          <TextInput disabled source="promotionName" alwaysOn label={translate('orders.filter.promotionName')} />
          <CheckboxGroupInput
            source="status.value"
            choices={ORDER_STATUS_FILTER}
            alwaysOn
            label={translate('orders.list.status')}
          />
        </Filter>
        <Toolbar>
          <ExportButton
            resource={listContext.resource}
            sort={listContext.currentSort}
            filterValues={listContext.filterValues}
            maxResults={10000}
            exporter={orders => exporter(orders, ordersPendingCompletion)}
            data-testid="export-orders-button"
          />
        </Toolbar>
      </TopToolbar>
      <Box
        alignSelf="center"
        justifyContent="center"
        display="flex"
      >
        <Typography>
          <Box alignSelf="baseline" fontWeight="bold">
            {`Orders pending completion: ${ordersPendingCompletion}`}
          </Box>
        </Typography>
      </Box>
      <Datagrid optimized expand={<OrderReferenceExpand />}>
        <LinkTextField
          source="promotion.key"
          basePath="promotion"
          label={translate('orders.list.promotionKey')}
          sortBy="promotion"
        />
        <LinkTextField
          source="promotion.name"
          basePath="promotion"
          label={translate('orders.list.promotionName')}
          sortable={false}
        />
        <DateField source="requestedAt" locales="fr-FR" label={translate('orders.list.requestedAt')} />
        <DateField source="expiredAt" locales="fr-FR" label={translate('orders.list.expiredAt')} />
        <DateField source="evaluatedAt" locales="fr-FR" label={translate('orders.list.evaluatedAt')} />
        <TextField source="pickupPoint.name" locales="fr-FR" label={translate('orders.list.retailer')} />
        <FunctionField
          source="status"
          render={record => (
            <StatusSelect
              id={record?.id}
              status={record?.status}
              promotionStatus={record?.promotion?.status?.key}
              promotionName={record?.promotion?.name}
              promotionType={record?.promotion?.promotionType?.name}
              retailerList={record?.promotion?.pickupPoints}
              orderRetailer={record?.pickupPoint?.id}
            />
          )}
        />
      </Datagrid>
      <Pagination {...listContext} />
    </ListContextProvider>
  );
};

const OrderReferenceList = (props) => {
  // remove an unwanted prop such as: hasShow, hasList, hasEdit, from spread operation
  const {
    username, record, hasShow, hasList, hasEdit, addLabel, id, ...rest
  } = props;
  const classes = useStyles(props);

  if (filterSet?.status?.value?.length) {
    filterSet = {
      ...filterSet,
      user: {
        op: 'IN',
        value: [id],
      },
    };
  } else {
    filterSet = {
      user: {
        op: 'IN',
        value: [id],
      },
    };
  }

  return (
    <div className={classes.content}>
      <ReferenceManyField
        key={username}
        reference="orders"
        target="username"
        filter={filterSet}
        sort={{
          // Sort needs to be fixed from BE before it can be implemented in FE
          // field: 'requestedAt', order: 'DESC',
        }}
        empty={<EmptyList />}
        {...rest}
      >
        <OrderList {...props} currentFilter={filterSet} />
      </ReferenceManyField>
    </div>
  );
};

export default OrderReferenceList;
