import React from 'react';

import {
  useTranslate,
} from 'react-admin';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import moment from 'moment';

import { CANCELLED, START_INDEX } from './constants';

const useStyles = makeStyles({
  root: { width: '80%', margin: 'auto' },
  spacer: { height: 20 },
  invoices: { margin: '10px 0' },
  table: {
    minWidth: 300,
    marginTop: 20,
  },
  cellBoldHeader: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  cellBoldTitle: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  cancellationReason: {
    marginLeft: 20,
  },
});

moment.locale('fr',
  {
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm',
    },
  });

const getFormattedDate = (date) => {
  if (date && moment(date).isValid()) {
    return moment(date).format('L');
  }
  return '';
};

const StatusHistory = ({ history }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const getDate = (date) => {
    if (date && moment(date).isValid()) {
      const utc = moment.utc(date).toDate();
      return moment(utc).local().format('L');
    }
    return null;
  };

  return (
    <TableContainer>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellBoldHeader} align="center" colSpan={5}>{translate('orders.list.statusHistory')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellBoldTitle} align="center">{translate('orders.list.num')}</TableCell>
            <TableCell className={classes.cellBoldTitle} align="center">{translate('orders.list.status')}</TableCell>
            <TableCell className={classes.cellBoldTitle} align="center">{translate('orders.list.date')}</TableCell>
            <TableCell className={classes.cellBoldTitle} align="center">{translate('orders.list.cancellationReason')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((row, index) => (
            <TableRow key={row.name}>
              <TableCell align="center">
                {index + START_INDEX}
              </TableCell>
              <TableCell align="center">{row.status}</TableCell>
              <TableCell align="center">{getDate(row.statusDate)}</TableCell>
              <TableCell align="center">{row.reason}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const OrderReferenceExpand = (props) => {
  const { record } = props;
  const classes = useStyles();
  const translate = useTranslate();
  if (!record) return null;
  const { status } = record;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container>
          <Grid item>
            {record.user?.username && (
              <Typography variant="h6">
                {`${translate('user.list.username')}: ${record.user?.username}`}
              </Typography>
            )}
            <Typography variant="h6">
              {`${translate('orders.list.requestedAt')}: ${getFormattedDate(record.requestedAt)}`}
            </Typography>
            <Typography variant="h6">
              {`${translate('orders.list.expiredAt')}: ${getFormattedDate(record.expiredAt)}`}
            </Typography>
            <Typography variant="h6">
              {`${translate('orders.list.redeemedAt')}: ${getFormattedDate(record.redeemedAt)}`}
            </Typography>
            <Typography variant="h6">
              {`${translate('orders.list.evaluatedAt')}: ${getFormattedDate(record.evaluatedAt)}`}
            </Typography>
            {status === CANCELLED && (
            <Typography variant="h6">
              {`${translate('orders.list.cancelledAt')}: ${getFormattedDate(record.cancelledAt)}`}
            </Typography>
            )}
            <Typography variant="h6">
              {`${translate('orders.list.retailer')}: ${record.pickupPoint?.name || ''}`}
            </Typography>
            <Typography variant="h6">
              {`${translate('promotion.list.state')}: ${record.status}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid>
              <StatusHistory history={record.statusHistory} spacing={2} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrderReferenceExpand;
