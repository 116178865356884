import _ from 'lodash';

export const QUESTION_TYPES = {
  LIST: 'radio-checkbox',
  INPUT: 'input',
  MATRIX: 'matrix',
};

export const selectTypeValues = [
  { id: '', name: '-' },
  { id: QUESTION_TYPES.LIST, name: 'Selection' },
  { id: QUESTION_TYPES.INPUT, name: 'Text/Number' },
  { id: QUESTION_TYPES.MATRIX, name: 'Matrix' },
];

export const inputTypeValues = [
  { id: undefined, name: '-' },
  { id: 'TEXT', name: 'Short text' },
  { id: 'TEXT_LONG', name: 'Long text' },
  { id: 'INT', name: 'Number without decimals' },
  { id: 'FLOAT', name: 'Number with decimals' },
];

const defaultMatrixAnswers = [
  'Totalmente de acuerdo',
  'Algo de acuerdo',
  'Ni de acuerdo ni en desacuerdo',
  'Algo en desacuerdo',
  'Totalmente en desacuerdo',
];

export const typeDictionary = (key) => {
  switch (key) {
    case 'questionList':
      return QUESTION_TYPES.LIST;
    case 'questionInput':
      return QUESTION_TYPES.INPUT;
    case 'questionMatrix':
      return QUESTION_TYPES.MATRIX;
    default:
      return undefined;
  }
};

const typeDictionaryForInput = (key) => {
  switch (key) {
    case QUESTION_TYPES.LIST:
      return 'questionList';
    case QUESTION_TYPES.INPUT:
      return 'questionInput';
    case QUESTION_TYPES.MATRIX:
      return 'questionMatrix';
    default:
      return undefined;
  }
};

export const parseAnswersFromRecord = (iType, answers) => {
  if (iType === QUESTION_TYPES.LIST) {
    if (answers?.length) {
      const output = [];

      answers.forEach((answer) => {
        output.push({
          ...answer,
          jumpToQuestionId:
            answer?.jumpToQuestionId === null ? undefined : answer?.jumpToQuestionId,
        });
      });
      return output;
    }
  }
  return answers;
};

const parseAnswerNode = (answer, random, options) => {
  if (random || options?.isMultiple) {
    return _.omit(answer, ['jumpToQuestionId', '__typename']);
  }

  return _.omit(answer, '__typename');
};

const parseInputByType = (input, random) => {
  switch (input.iType) {
    case QUESTION_TYPES.LIST:
      return {
        id: input.id,
        answersAvailable:
          input?.answersAvailable?.map(answer => parseAnswerNode(answer, random, input?.options)),
        options: _.omit(input.options, '__typename'),
        title: input.title,
      };
    case QUESTION_TYPES.INPUT:
      return {
        id: input.id,
        max: input.max,
        min: input.min,
        title: input.title,
        type: input.type,
      };
    case QUESTION_TYPES.MATRIX:
      return {
        answersAvailable: input.answersAvailable,
        id: input.id,
        questions: input.questions,
        title: input.title,
      };
    default:
      return null;
  }
};

export const parseQuestionsNodeInput = (input, random) => {
  if (input) {
    const parsedInput = [];

    input.map(item => parsedInput.push({
        [typeDictionaryForInput(item.iType)]: {
          ...parseInputByType(item, random),
        },
      }));

    return parsedInput;
  }

  return input;
};

export const getDefaultValuesByType = (type) => {
  switch (type) {
    case QUESTION_TYPES.INPUT:
      return {
        max: 0,
        min: 0,
        title: '',
        type: undefined,
      };
    case QUESTION_TYPES.MATRIX:
      return {
        answersAvailable: defaultMatrixAnswers,
        questions: [],
        title: '',
      };
    case QUESTION_TYPES.LIST:
    default:
      return {
        answersAvailable: [],
        title: '',
        options: {
          isMultiple: false,
        },
      };
  }
};

const validateSurveyQuestions = (questions) => {
  const errors = {};
  const zero = 0;

  questions.forEach((question) => {
    const key = Object.keys(question)[0];
    const value = question[key];

    if (key === 'questionList') {
      const { answersAvailable, id, title } = value;

      if (!answersAvailable || answersAvailable?.length === zero) {
        errors[`q_${id}`] = 'You must add at least one answer';
      }
      if (answersAvailable && answersAvailable.length > zero) {
        answersAvailable.forEach((answer) => {
          const { id: answerId, title: answerTitle } = answer;
          if (!answerTitle || answerTitle === '') {
            errors[`q_${id}_a_${answerId}`] = 'This field is mandatory';
          }
        });
      }
      if (!title || title === '') {
        errors[`q_${id}_title`] = 'This field is mandatory';
      }
    }

    if (key === 'questionInput') {
      const { id, title, type } = value;

      if (!title || title === '') {
        errors[`q_${id}_title`] = 'This field is mandatory';
      }

      if (!type || type === '') {
        errors[`q_${id}_type`] = 'This field is mandatory';
      }
    }

    if (key === 'questionMatrix') {
      const { id, questions: matrixQuestions, title } = value;

      if (!matrixQuestions || matrixQuestions?.length === zero) {
        errors[`q_${id}`] = 'You must add at least one question header';
      }
      if (!title || title === '') {
        errors[`q_${id}_title`] = 'This field is mandatory';
      }
    }
  });

  return errors;
};

export const validateSurveyForm = (data) => {
  const zero = 0;
  let output = {};

  if (data) {
    const { input } = data;

    if (!input?.name) {
      output.name = 'This field is mandatory';
    }

    if (!input?.questions || input?.questions.length === zero) {
      output.questions = 'You must add at least one question';
    }

    if (input?.questions && input?.questions?.length) {
      const questionsValidation = validateSurveyQuestions(input.questions);
      output = {
        ...output,
        ...questionsValidation,
      };
    }
  }

  return output;
};
