import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useRefresh } from 'ra-core';
import { Button, Toolbar, useDataProvider, useNotify, useTranslate } from 'react-admin';
import _ from 'lodash';
import { Box, InputLabel, MenuItem, Select } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import surveyStyles from './surveyStyles';
import { parseAnswersFromRecord, parseQuestionsNodeInput, typeDictionary } from '../../lib/utils/surveys';

const SurveyRouter = (props) => {
  const { recordData } = props;
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [flow, setFlow] = useState(recordData?.flow);
  const [surveyName, setSurveyName] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [random, setRandom] = useState(undefined);
  const [recQuestions, setRecQuestions] = useState(undefined);
  const classes = surveyStyles();

  useLayoutEffect(() => {
    if (recordData) {
      const {
        active, name, options, questions,
      } = recordData;

      setSurveyName(name);
      setStatus(active);
      setRandom(options?.randomEnabled);

      if (questions) {
        const parsed = [];
        questions.map((question) => {
          const parsedQuestion = _.omit(question, '__typename');
          if (typeof parsedQuestion === 'object') {
            /* eslint-disable */
            for (const [key, value] of Object.entries(parsedQuestion)) {
              if (value !== null) {
                const iType = typeDictionary(key);
                parsed.push({
                  ...value,
                  iType,
                  answersAvailable: parseAnswersFromRecord(iType, value?.answersAvailable),
                });
              }
            }
            /* eslint-enable */
          }

          return true;
        });
        setRecQuestions(parsed);
      }
    }
  }, [recordData]);

  const parsedInput = useMemo(() => ({
      id: recordData?.id,
      input: {
        active: status,
        flow,
        name: surveyName,
        options: {
          randomEnabled: random,
        },
        questions: parseQuestionsNodeInput(recQuestions, random),
      },
    }
  ), [recordData, status, surveyName, random, recQuestions, flow]);

  const choices = [
    { id: 'ROUTING', name: t('surveys.router.status.on') },
    { id: 'EMAIL', name: t('surveys.router.status.off') },
  ];

  const handleChangeFlow = (e) => {
    setFlow(e.target.value);
  };

  const handleSubmit = () => {
    dataProvider.update('surveys', {
      data: {
        ...parsedInput,
      },
    })
      .then(() => {
        notify(t('surveys.router.success'), 'success');
        refresh();
      })
      .catch(err => {
        notify(err?.message, 'error');
      });
  };

  return (
    <div className={classes.formControl}>
      <div className={classes.routerWrapper}>
        <div className={classes.routerStatusDiv}>
          <InputLabel>{t('surveys.router.status.label')}</InputLabel>
          <Select name="flow" defaultValue={flow} onChange={handleChangeFlow} style={{ width: '230px' }}>
            {choices.map(item => (
              <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
        </div>
        <Toolbar>
          <Box display="flex">
            <Button
              variant="contained"
              label={t('surveys.router.submit')}
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
              style={{ maxWidth: '172px' }}
            />
          </Box>
        </Toolbar>
      </div>
    </div>
  );
};

export default SurveyRouter;
