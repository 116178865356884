import React from 'react';
import {
  Filter, TextInput, minLength, maxLength, regex, DateInput, SelectInput, SelectArrayInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { STATUS_TYPES } from '../../lib/utils/Comments';
import { RATING } from '../promotions/constants';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  filter: {
    height: '70px',
  },
}));
const CommentFilter = ({ translate, permissions, ...props }) => {
  const maxSearch = 13;
  const minSearch = 6;
  const minimal = 3;
  const validatePatternNum = regex(/^\d*$/, 'Please introduce between 6 and 13 digits');
  const validateEan = [
    minLength(minSearch, 'Please introduce between 6 and 13 digits'),
    maxLength(maxSearch, 'Please introduce between 6 and 13 digits'),
    validatePatternNum,
  ];
  const validateMinLength = [minLength(minSearch)];
  const validateMinimal = [minLength(minimal)];
  const classes = useStyles();

  return (
    <Filter {...props} data-testid="comments-search">
      <TextInput className={classes.filter} validate={validateEan} source="ean" alwaysOn label={translate('comment.edit.ean')} />
      <TextInput className={classes.filter} validate={validateMinLength} source="username" alwaysOn label={translate('comment.edit.username')} />
      <TextInput className={classes.filter} validate={validateMinimal} source="search" alwaysOn label={translate('comment.edit.text')} />
      <SelectInput
        label={translate('comment.edit.status')}
        source="status"
        choices={[
          { id: STATUS_TYPES.REJECTED, name: STATUS_TYPES.REJECTED },
          { id: STATUS_TYPES.PUBLISHED, name: STATUS_TYPES.PUBLISHED },
        ]}
        options={{ style: { minWidth: '125px' } }}
        allowEmpty={false}
      />
      <SelectArrayInput
        className={classes.filter}
        multiple
        label={translate('comment.edit.rating')}
        source="filterRating"
        choices={RATING}
        options={{ style: { minWidth: '85px' } }}
      />
      <DateInput source="startDateFrom" label={translate('comment.list.filter.startDate')} />
      <DateInput source="startDateUntil" label={translate('comment.list.filter.endDate')} />
    </Filter>
  );
};

export default CommentFilter;
