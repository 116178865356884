import React, { useState, useEffect, cloneElement } from 'react';

import {
  ArrayInput,
  FormDataConsumer,
  SimpleForm,
  maxLength,
  SimpleFormIterator,
  TextInput,
  useEditController,
  useTranslate,
  Loading,
  Edit,
  Toolbar,
  SaveButton,
  useNotify,
  NumberInput,
} from 'react-admin';
import { useRefresh } from 'ra-core';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import _, { cloneDeep } from 'lodash';
import { Prompt } from 'react-router-dom';
import { FormLabel, Button } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  PROMO_EAN_MAX_FIELDS,
  PROMO_EAN_MAX_LENGTH,
  ONE,
  ACTIVE_STATUS,
  AUTO_PUBLISH_STATUS,
  STATUS,
  RADIX_TEN,
  EMPTY,
} from './constants';

import {
  PromotionWithdrawalValidation,
  PromotionWithdrawalMandatory,
  validatePromotionFields,
  validateDateStartDate,
  validateDateEndDate,
  validateRetailers,
  validateOrderValidity,
} from './PromotionUtils';

import RichTextEditor from '../../lib/components/RichTextEditor';
import UploadImage from './HandleImage/UploadImage';
import StatusDropdown from './StatusDropdown';
import { TabbedLayout, Tab } from '../../lib/components';
import OrderReferenceList from './OrderReferenceList';
import StatusDateInput from './StatusDateInput';
import NumericInput from './NumericInput';
import MultiSelectRetailerStocks from './MultiSelectRetailerStocks';

import { promotionFormStyles, StyledTableRow } from './promotionFormStyles';
import CreateSurveyButton from './CreateSurveyButton';
import SegmentationForm from '../../lib/components/SegmentationForm';

const IMAGE_WIDTH = 400;
const IMAGE_HEIGHT = 400;
const REGEX_EAN = /^.*?\[[^\d]*(\d+)[^\d]*\].*$/;
const DETAIL = 'DETAIL';
const ORDER = 'ORDER';
const SEGMENTATION = 'SEGMENTATION';
const MIN_POINTS = 0;

let recordData;

/*
  Custom Edit Purpose : Save the server response for comparison.
  StartDate and  EndDate may contain past date at the time of editig.
  In this situation user can select the current date or future date  or The past date itself.
  To accomplish this functionality we are saving server response to recordData
*/


const CustomEdit = (props) => {
  const {
    record,
    ...rest
  } = useEditController(props);
  const [selectedTab, setSelectedTab] = useState(DETAIL);
  const { children, resetRecordValues } = props;
  const translate = useTranslate();
  const classes = promotionFormStyles();
  recordData = record;

  return (
    <div className={classes.root}>
      <TabbedLayout>
        <Tab
          label={translate('user.tabs.promoDetail')}
          onClick={() => {
            if (selectedTab !== DETAIL) {
              resetRecordValues();
              setSelectedTab(DETAIL);
            }
          }}
        >
          <Card>
            {cloneElement(children, {
              record,
              ...rest,
            })}
          </Card>
        </Tab>
        <Tab
          label={translate('user.tabs.orders')}
          path="orders"
          onClick={() => {
            setSelectedTab(ORDER);
          }}
        >
          {
            !recordData && <Loading />
          }
          {recordData && recordData.id
            && <OrderReferenceList promotionId={recordData.id} {...props} />
          }
        </Tab>
        <Tab
          label={translate('user.tabs.promoSegmentation')}
          path="segmentation"
          onClick={() => {
            setSelectedTab(SEGMENTATION);
          }}
        >
          {
            !recordData && <Loading />
          }
          {recordData && recordData.uuid
            && <SegmentationForm promotionUuid={recordData.uuid} />
          }
        </Tab>
      </TabbedLayout>
    </div>
  );
};

const RemoveEanButton = withStyles(theme => ({
  root: {
    color: theme.overrides.MuiButton.root.color,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))(Button);

const EditFormWithdrawal = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const {
    record,
  } = useEditController(props);
  const refresh = useRefresh();
  const [validationProvider, setValidationProvider] = useState(PromotionWithdrawalValidation);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currStatus, setCurrStatus] = useState(null);
  const [recordValues, setRecordValues] = useState(null);
  const [eansLength, setEansLength] = useState(null);
  const [surveyError, setSurveyError] = useState(null);
  const [stockTotals, setStockTotals] = useState({
    initialStock: 0,
    availableStock: 0,
    realStock: 0,
  });
  const notify = useNotify();
  const classes = promotionFormStyles();
  const mustStatus = [ACTIVE_STATUS, AUTO_PUBLISH_STATUS];
  const zero = 0;

  const updateRecordVal = (key, val) => {
    const newRecordValue = { ...recordValues, [key]: val.target?.value };
    setRecordValues(newRecordValue);
  };

  const updateInitialStockValue = (index, initialStock) => {
    const actual = recordValues.pickupPointStocks;
    const safetyStock = recordValues?.promotionStock?.safetyStock ?? zero;
    const percentDivisor = 100;
    const newActual = Object.entries(actual).map(([i, row]) => {
      const calculatedStock = parseInt(
        (initialStock - ((initialStock * safetyStock) / percentDivisor)).toFixed(),
        RADIX_TEN,
      );
      return (
        i === index
          ? {
            ...row,
            initialStock: parseInt(initialStock, RADIX_TEN),
            availableStock: calculatedStock,
            realStock: calculatedStock,
          } : row
      );
    });

    updateRecordVal('pickupPointStocks', { target: { value: newActual } });
  };

  useEffect(() => {
    setStartDate(record?.startDate);
    setEndDate(record?.endDate);
    setCurrStatus(record?.status?.key);
    setRecordValues(record);
    setEansLength(record?.eans?.length);
  }, [record]);

  useEffect(() => {
    const statusKey = currStatus || record?.status?.key;
    if (!mustStatus.includes(statusKey)) {
      setValidationProvider(PromotionWithdrawalValidation);
    } else if (mustStatus.includes(statusKey)) {
      setValidationProvider(PromotionWithdrawalMandatory);
    }
    /* eslint-disable-next-line */
  }, [currStatus]);

  useEffect(() => {
    if (recordValues?.pickupPointStocks) {
      let initialStock = EMPTY;
      let availableStock = EMPTY;
      let realStock = EMPTY;

      recordValues.pickupPointStocks.forEach((row) => {
        initialStock += row?.initialStock || EMPTY;
        availableStock += row?.availableStock || EMPTY;
        realStock += row?.realStock || EMPTY;
      });

      setStockTotals({ initialStock, availableStock, realStock });
    }
    /* eslint-disable-next-line */
  }, [recordValues?.pickupPointStocks]);

  const onFailure = (error) => {
    const INCOMPLETE_SURVEY = 'GraphQL error: Survey for this promotion is incomplete.';
    const NO_SURVEY = 'GraphQL error: This promotion doesnt have an associated survey.';

    if (error.message === INCOMPLETE_SURVEY) {
      setSurveyError(translate('promotion.survey.incompleteError'));
      notify(translate('promotion.survey.incompleteError'), 'warning');
    } else if (error.message === NO_SURVEY) {
      setSurveyError(translate('promotion.survey.notCreatedError'));
      notify(translate('promotion.survey.notCreatedError'), 'warning');
    } else {
      notify(error.message, 'warning');
    }
  };

  const onSuccess = () => {
    setSurveyError(null);
    notify(translate('promotion.edit.update'));
    refresh();
  };

  const InformationView = () => (
    <Grid>
      <Grid item>
        <FormLabel className={classes.helpLabel}>{translate('image.upload.format')}</FormLabel>
        <FormLabel className={classes.helpContent}>{translate('image.upload.formatValue')}</FormLabel>
      </Grid>
      <Grid item>
        <FormLabel className={classes.helpLabel}>{translate('image.upload.weight')}</FormLabel>
        <FormLabel className={classes.helpContent}>{translate('image.upload.weightValue')}</FormLabel>
      </Grid>
      <Grid item>
        <FormLabel className={classes.helpLabel}>{translate('image.upload.size')}</FormLabel>
        <FormLabel className={classes.helpContent}>{translate('image.upload.sizeValue')}</FormLabel>
      </Grid>
    </Grid>
  );


  const PromotionToolBar = promProps => (
    <Toolbar {...promProps} className={classes.fixedSaveButton}>
      <SaveButton />
    </Toolbar>
  );

  const PromptUnSaved = () => (
    <Prompt
      when={JSON.stringify(record) !== JSON.stringify(recordValues)}
      message={translate('promotion.edit.alert')}
    />
  );

  const renderStockPerRetailer = () => (
    <TableContainer>
      <Table className={classes.tableGeneral}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableTitle}>Name</TableCell>
            <TableCell align="center" className={classes.tableTitle}>Initial Stock</TableCell>
            <TableCell align="center" className={classes.tableTitle}>Available Stock</TableCell>
            <TableCell align="center" className={classes.tableTitle}>Real Stock</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recordValues?.pickupPointStocks
            && Object.entries(recordValues.pickupPointStocks).map(([index, row]) => (
              <StyledTableRow key={row.pickupPoint.id}>
                <TableCell component="th" scope="row">{row.pickupPoint.name}</TableCell>
                <TableCell align="center">
                  <NumberInput
                    source={`pickupPointStocks[${index}].initialStock`}
                    defaultValue={row.initialStock}
                    className={classes.retailerStockInput}
                    label=""
                    variant="outlined"
                    onBlur={e => updateInitialStockValue(index, e?.target?.value || zero)}
                  />
                </TableCell>
                <TableCell align="center">{row.availableStock || '0'}</TableCell>
                <TableCell align="center">{row.realStock || '0'}</TableCell>
              </StyledTableRow>
            ))}
          <TableRow className={classes.totalsRow}>
            <TableCell align="right">Totals</TableCell>
            <TableCell align="center">{stockTotals.initialStock}</TableCell>
            <TableCell align="center">{stockTotals.availableStock}</TableCell>
            <TableCell align="center">{stockTotals.realStock}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  const updateEans = (val) => {
    const index = parseInt(REGEX_EAN.exec(val.target.id)[ONE], RADIX_TEN);
    const eans = cloneDeep(recordValues.eans);
    if (eans.length - ONE < index) {
      eans.push({
        value: val.target.value,
      });
    } else {
      eans[index].value = val.target.value;
    }
    updateRecordVal('eans', { target: { value: eans } });
    setEansLength(eans.length);
  };

  const resetRecordValues = () => {
    setRecordValues(record);
  };


  const handleOnRemoveEan = () => {
    const ONE_ELEMENT = 1;
    setEansLength(eansLength - ONE_ELEMENT);
  };

  const customRemoveButton = () => (
    <RemoveEanButton
      variant="contained"
      color="default"
      startIcon={<RemoveCircleOutlineIcon />}
      onClick={() => handleOnRemoveEan(recordValues)}
    >
      Remove
    </RemoveEanButton>
  );

  const parseRetailerStocksValues = (values) => {
    const output = [];

    values.forEach((value) => {
      if (_.hasIn(value, 'pickupPoint.id')) {
        output.push(value);
      } else {
        const previousValue = record?.pickupPointStocks?.filter(
          item => item.pickupPoint.id === value.id,
        );
        output.push({
          pickupPoint: {
            id: value.id,
            name: value.name,
          },
          availableStock: previousValue?.availableStock || zero,
          initialStock: previousValue?.initialStock || zero,
          realStock: previousValue?.realStock || zero,
        });
      }
    });

    return output === [] ? null : output;
  };

  return (
    <CustomEdit {...props} undoable={false} resetRecordValues={resetRecordValues}>
      <Edit
        {...props}
        onSuccess={onSuccess}
        onFailure={onFailure}
        actions={null}
        undoable={false}
        title={`Promotion #${record?.key}`}
      >
        <SimpleForm
          redirect={false}
          validate={(value) => {
            validatePromotionFields(value);
          }}
          data-testid="promotion-edit-form"
          toolbar={<PromotionToolBar />}
        >
          <PromptUnSaved recordValues={recordValues} />
          <div className={classes.form}>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLiteCentered}>
                  <InfoTwoToneIcon />
                  <p>{translate('promotion.edit.promotionType')}: {record?.promotionType?.name}</p>
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.name')}</div>
                </div>
                <div className={classes.normalBox}>
                  <TextInput
                    source="name"
                    fullWidth
                    validate={validationProvider.promotionName}
                    resettable
                    label={translate('promotion.edit.name')}
                    onChange={val => updateRecordVal('name', val)}
                  />
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.status')}</div>
                </div>
                <div className={classes.normalBox}>
                  <StatusDropdown
                    source="status.key"
                    label={translate('promotion.edit.status')}
                    choices={STATUS}
                    className={classes.formControlFields}
                    currStatus={currStatus}
                    setStatus={(status) => {
                      setCurrStatus(status);
                      updateRecordVal('status.key', { target: { value: status } });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.eans')}</div>
                </div>
                <div className={classes.eanBox}>
                  <FormDataConsumer>
                    {({ formData: { eans } }) => (
                      <Grid item sm={12} md={10} lg={4}>
                        <ArrayInput
                          source="eans"
                          label=""
                          onChange={updateEans}
                        >
                          <SimpleFormIterator
                            disableReordering
                            disableAdd={eans && eans.length >= PROMO_EAN_MAX_FIELDS}
                            className={classes.iteratorFormItem}
                            removeButton={customRemoveButton()}
                          >
                            <TextInput
                              label=""
                              source="value"
                              validate={maxLength(PROMO_EAN_MAX_LENGTH)}
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </Grid>
                    )
                    }
                  </FormDataConsumer>
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.image')}</div>
                </div>
                <div className={classes.imageBox}>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <UploadImage
                        {...props}
                        id={formData.uuid}
                        source="imageUrl"
                        accept="image/jpeg,image/png,image/jpg"
                        originalUrl={formData.imageUrl}
                        maxSize={5000000}
                        customSource="imagePromo"
                        previewWidthAndHeight={{ width: 250, height: 'auto' }}
                        label={translate('image.promotionImage')}
                        placeholder={<p>{translate('image.ImageDropLabel')}</p>}
                        maxImageWidth={IMAGE_WIDTH}
                        maxImageHeight={IMAGE_HEIGHT}
                        validate={validationProvider.promotionImageUpload}
                        informationComponent={() => <InformationView />}
                        onChange={updateRecordVal}
                      />
                    )}
                  </FormDataConsumer>
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.surveyButton')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.buttonContainer}>
                    <CreateSurveyButton
                      recordValues={recordValues}
                      recordData={recordData}
                      eansLength={eansLength}
                      errorMessage={surveyError}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.surveyInfo')}</div>
                </div>
                <div className={classes.normalBoxLite}>
                  <p>{translate('promotion.edit.createSurveyInfo')}</p>
                </div>
                <div className={classes.normalBoxLite}>
                  <p>{translate('promotion.edit.editSurveyInfo')}</p>
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.points')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <NumericInput
                      source="points"
                      label={translate('promotion.edit.points')}
                      type="number"
                      className={classes.formControlFields}
                      min={MIN_POINTS}
                      validate={validationProvider.promotionPoints}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rowCol} />
            </div>
            <div className={classes.mainRow}>
              <div className={classes.fullRowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.stock')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <FormDataConsumer>
                      {({ formData: { pickupPointStocks, retailersList } }) => (
                        <MultiSelectRetailerStocks
                          source="pickupPointStocks"
                          label={translate('promotion.edit.retailers')}
                          retailers={pickupPointStocks || []}
                          retailersList={retailersList || []}
                          className={classes.formControlFields}
                          validate={(val) => {
                            validateRetailers(val, currStatus);
                          }}
                          onChangeValue={(value) => {
                            updateRecordVal('pickupPointStocks', {
                              target: { value: parseRetailerStocksValues(value) },
                            });
                          }}
                        />
                      )
                      }
                    </FormDataConsumer>
                  </div>
                  <div className={classes.normalBoxRight}>
                    <NumberInput
                      fullWidth
                      source="promotionStock.safetyStock"
                      resettable
                      label={translate('promotion.edit.safetyStockCommon')}
                      maxValue={100}
                      type="number"
                      className={classes.formControlFields}
                      validate={validationProvider.promotionSafetyStock}
                      status={currStatus}
                      onChange={(value) => {
                        updateRecordVal('promotionStock.safetyStock', {
                          target: { value },
                        });
                      }}
                      initialValue={zero}
                    />
                  </div>
                </div>
                <div className={classes.normalBoxLite}>
                  <div className={classes.normalBoxFull}>
                    {recordValues?.pickupPointStocks && renderStockPerRetailer()}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.fullRowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.richTexts')}</div>
                </div>
                <div className={classes.bigBox}>
                  <RichTextEditor
                    multiline
                    fullWidth
                    source="description"
                    resettable
                    validate={validationProvider.promotionDescription}
                    label={translate('promotion.edit.description')}
                    curValue={record?.description}
                    onChangeValue={val => updateRecordVal('description', val)}
                  />
                </div>
                <div className={classes.bigBox}>
                  <RichTextEditor
                    multiline
                    fullWidth
                    source="legalText"
                    resettable
                    validate={validationProvider.promotionLegalText}
                    label={translate('promotion.edit.legalText')}
                    curValue={record?.legalText}
                    onChangeValue={val => updateRecordVal('legalText', val)}
                  />
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.dates')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <StatusDateInput
                      source="startDate"
                      validate={date => validateDateStartDate(date, endDate, currStatus,
                        record?.startDate, record?.status?.key)}
                      label={translate('promotion.edit.startAt')}
                      onChangeDate={(date) => {
                        setStartDate(date.target.value);
                        updateRecordVal('startDate', date);
                      }}
                      currStatus={currStatus}
                      setCurrStatus={setCurrStatus}
                    />
                  </div>
                  <div className={classes.normalBoxRight}>
                    <StatusDateInput
                      source="endDate"
                      validate={date => validateDateEndDate(date, startDate)}
                      label={translate('promotion.edit.endAt')}
                      onChangeDate={(date) => {
                        setEndDate(date.target.value);
                        updateRecordVal('endDate', date);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.order')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <NumericInput
                      fullWidth
                      source="daysForOrdersValidity"
                      resettable
                      label={translate('promotion.edit.orderValidity')}
                      type="number"
                      className={classes.numInput}
                      validate={
                        orderValidity => validateOrderValidity(orderValidity, currStatus, translate)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimpleForm>
      </Edit>
    </CustomEdit>
  );
};

export default EditFormWithdrawal;
