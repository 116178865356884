import React, { useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';

import surveyStyles from '../surveyStyles';

const DragWarning = (props) => {
  const {
    jumpWarning,
    setJumpWarning,
    jumpData,
    recordQuestions,
  } = props;
  const classes = surveyStyles();

  const text = useMemo(() => {
    if (!jumpData) {
      return null;
    }
    let output = '';
    jumpData.forEach((item) => {
      const fromTitle = recordQuestions.find(el => el.id === item.from)?.title;
      const toTitle = recordQuestions.find(el => el.id === item.to)?.title;
      output += `<li>- From <b>${fromTitle}</b> to <u>${toTitle || 'Deleted question'}</u></li>`;
    });

    return `<ul>${output}</ul>`;
  }, [jumpData, recordQuestions]);

  return (
    <Dialog open={jumpWarning} onClose={() => setJumpWarning(false)}>
      <DialogTitle>Warning!</DialogTitle>
      <DialogContent className={classes.warningList}>
        <Typography>The following jumps have been removed:</Typography>
        {/* eslint-disable-next-line */}
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setJumpWarning(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DragWarning;
