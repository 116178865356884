const REJECTED = 'REJECTED';
const PUBLISHED = 'PUBLISHED';
const STATUS_TYPES = {
  REJECTED,
  PUBLISHED,
};

const RATING = [{
  id: 1,
  name: '○ 1',
},
  {
    id: 2,
    name: '○ 2',
  },
  {
    id: 3,
    name: '○ 3',
  },
  {
    id: 4,
    name: '○ 4',
  },
  {
    id: 5,
    name: '○ 5',
  },
];

export {
 REJECTED, PUBLISHED, STATUS_TYPES, RATING,
};
