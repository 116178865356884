import * as React from 'react';
import {
  List, Datagrid, TextField, BooleanField, FunctionField,
} from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import { EmptyList } from '../../lib/components';

const ProductList = props => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    empty={<EmptyList />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Survey name" sortable={false} />
      <TextField source="createdAt" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <TextField source="totalAnswered" sortable={false} />
      <FunctionField label="Is routing?" render={record => {
        return record.flow === 'ROUTING' ? <CheckIcon /> : <ClearIcon />;
      }} />
    </Datagrid>
  </List>
);

export default ProductList;
