import gql from 'graphql-tag';
import client from '../../providers/client';
import VALIDATE_INPUT_PROMOTION_SERVICE from '../../providers/queries/validateInputPromotionService';

const validateInput = (input, mutation) => client.query({
    query: gql`${VALIDATE_INPUT_PROMOTION_SERVICE}`,
    variables: {
      input,
      mutation,
    },
  })
    .then(res => res)
    .catch(err => err.message);

export default validateInput;
