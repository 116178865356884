import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { RadioButtonGroupInput, TextInput } from 'react-admin';
import SunEditor from 'suneditor-react';

import { promotionFormStyles } from '../promotions/promotionFormStyles';

const StaticForm = (props) => {
  const {
    splitContents,
    setSplitContents,
    contentDesktop,
    setContentDesktop,
    contentMobile,
    setContentMobile,
  } = props;
  const classes = promotionFormStyles();

  const buttonList = [
    ['undo', 'redo'],
    ['fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
    ['outdent', 'indent', 'align', 'horizontalRule', 'lineHeight'],
    ['list', 'table', 'link', 'image', 'showBlocks', 'preview'],
  ];

  const handleChangeSplit = (event) => {
    setSplitContents(event.target.checked);
  };

  return (
    <>
      <div className={classes.mainRow}>
        <div className={classes.rowCol}>
          <div className={classes.normalBoxLite}>
            <div className={classes.sectionTitle}>Information</div>
          </div>
          <div className={classes.normalBoxColumn}>
            <Box className={classes.normalBoxLite}>
              <TextInput
                source="name"
                fullWidth
                label="Title"
              />
            </Box>
            <Box className={classes.normalBoxLite}>
              <TextInput
                source="alias"
                fullWidth
                label="Alias"
              />
            </Box>
          </div>
        </div>
        <div className={classes.rowCol}>
          <div className={classes.normalBoxLite}>
            <div className={classes.sectionTitle}>Status and Configuration</div>
          </div>
          <div className={classes.normalBox}>
            <div className={classes.normalBoxLeft}>
              <Box>
                <RadioButtonGroupInput
                  source="status"
                  choices={[
                    { id: 'REJECTED', name: 'REJECTED' },
                    { id: 'PUBLISHED', name: 'PUBLISHED' },
                  ]}
                  fullWidth
                  hiddenLabel
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={splitContents} onChange={handleChangeSplit} />}
                  label="Split contents (desktop and mobile)"
                />
              </Box>
            </div>
            <div className={classes.normalBoxRight}>
              <Box>
                <TextInput
                  source="position"
                  label="Order N#"
                />
              </Box>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.mainRow}>
        <div className={classes.fullRowCol}>
          <div className={classes.normalBoxLite}>
            <div className={classes.sectionTitle}>{splitContents ? 'Content for desktop' : 'Content'}</div>
          </div>
          <div className={classes.normalBoxFull}>
            <SunEditor
              setContents={contentDesktop}
              onChange={data => setContentDesktop(data)}
              setOptions={{ buttonList }}
              setDefaultStyle="font-family: Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif;height: auto;min-height: 400px;"
            />
          </div>
        </div>
      </div>

      {splitContents && (
        <div className={classes.mainRow}>
          <div className={classes.fullRowCol}>
            <div className={classes.normalBoxLite}>
              <div className={classes.sectionTitle}>Content for mobile</div>
            </div>
            <div className={classes.normalBoxFull}>
              <SunEditor
                setContents={contentMobile}
                onChange={data => setContentMobile(data)}
                setOptions={{ buttonList }}
                setDefaultStyle="font-family: Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif;height: auto;min-height: 400px;"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StaticForm;
