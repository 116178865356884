import * as React from 'react';

import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Filter,
  TextInput,
  SelectInput,
  minLength,
  regex,
  maxLength,
} from 'react-admin';
import { EmptyList } from '../../lib/components';


const maxSearch = 13;
const minSearch = 6;
const minSearchText = 3;
const validateMinimal = [minLength(minSearchText)];
const validatePatternNum = regex(/^\d*$/, 'Please introduce between 6 and 13 digits');
const validateEan = [
  minLength(minSearch, 'Please introduce between 6 and 13 digits'),
  maxLength(maxSearch, 'Please introduce between 6 and 13 digits'),
  validatePatternNum,
];
const ProductsFilter = ({ ...props }) => (
  <Filter style={{ alignItems: 'baseline' }} {...props} data-testid="product-filter">
    <TextInput validate={validateEan} source="ean" alwaysOn label="EAN" />
    <TextInput validate={validateMinimal} source="name" alwaysOn label="Name" />
    <SelectInput
      label="Status"
      source="active"
      choices={[
        { id: true, name: 'ACTIVE' },
        { id: false, name: 'INACTIVE' },
      ]}
      options={{ style: { width: '125px' } }}
      alwaysOn
    />
  </Filter>
);

const ProductList = props => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    empty={<EmptyList />}
    filters={(
      <ProductsFilter />
    )}
  >
    <Datagrid rowClick="edit">
      <TextField source="ean" label="EAN" sortable={false} />
      <TextField source="name" sortable={false} />
      <BooleanField source="active" sortable={false} />
    </Datagrid>
  </List>
);

export default ProductList;
