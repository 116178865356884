import React from 'react';
import { useFormState } from 'react-final-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';

import surveyStyles from '../surveyStyles';

const Matrix = (props) => {
  const { errors } = useFormState();
  const classes = surveyStyles();
  const {
    touched,
    addTouched,
    questions,
    title,
    id,
    changeQuestionTitle,
    recordQuestions,
    setRecordQuestions,
  } = props;

  const onChangeTitle = (e) => {
    changeQuestionTitle(id, e.target.value);
  };

  const onChangeQuestionValue = (key, value) => {
    const target = recordQuestions?.find(item => item.id === id);
    const { questions: questionsTarget } = target;
    const toChange = [];
    questionsTarget.forEach((item, itemIndex) => {
      toChange.push(itemIndex === key ? value : item);
    });
    const modified = {
      ...target,
      questions: toChange,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  const onRemoveQuestion = (key) => {
    const target = recordQuestions?.find(item => item.id === id);
    const { questions: questionsTarget } = target;
    const toChange = [];
    questionsTarget.forEach((item, itemIndex) => {
      if (itemIndex !== key) {
        toChange.push(item);
      }
    });
    const modified = {
      ...target,
      questions: toChange,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  const onAddQuestion = () => {
    const target = recordQuestions?.find(item => item.id === id);
    const { questions: questionsTarget } = target;
    const toChange = [...questionsTarget, ''];
    const modified = {
      ...target,
      questions: toChange,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  return (
    <div className={classes.questionListContainer}>
      <div style={{ width: '100%' }}>
        <Accordion style={{ backgroundColor: 'transparent' }}>
          <AccordionSummary
            className={classes.questionListAccordion}
            expandIcon={<ExpandMoreIcon />}
          >
            <FormControlLabel
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={<TextField defaultValue={title} name={`q_${id}_title`} label="Title *" onFocus={() => addTouched(`q_${id}_title`)} onChange={onChangeTitle} fullWidth error={touched?.current?.[`q_${id}_title`] && errors[`q_${id}_title`]} helperText={touched?.current?.[`q_${id}_title`] && errors[`q_${id}_title`]} />}
              label=""
            />
            {errors?.[`q_${id}`] && (
              <div className={classes.questionsErrorWrapper}>
                <ErrorIcon />
                <Typography color="error" variant="caption">{errors?.[`q_${id}`]}</Typography>
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.questionListAnswersWrapper}>
            <Typography variant="h4" color="primary">Question headers</Typography>
            {questions && questions.map((item, index) => {
              const keyString = `${id}_question_k${index}_m`;
              return (
                <div className={classes.answersContainer} key={`matrix_${keyString}`}>
                  <TextField
                    defaultValue={item}
                    variant="outlined"
                    value={questions[index]}
                    onFocus={() => addTouched(`q_${id}_matrix_${index}`)}
                    onChange={e => onChangeQuestionValue(index, e.target.value)}
                    error={touched?.current?.[`q_${id}_matrix_${index}`] && errors[`q_${id}_matrix_${index}`]}
                    helperText={touched?.current?.[`q_${id}_matrix_${index}`] && errors[`q_${id}_matrix_${index}`]}
                    fullWidth
                  />
                  <RemoveIcon
                    className={classes.pointer}
                    onClick={() => onRemoveQuestion(index)}
                  />
                </div>
              );
            })}
            <AddCircleIcon style={{ margin: '0 auto' }} className={classes.pointer} onClick={onAddQuestion} />
          </AccordionDetails>
        </Accordion>
      </div>
      <div />
    </div>
  );
};

export default Matrix;
