import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel, MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import LastPageIcon from '@material-ui/icons/LastPage';

import surveyStyles from '../surveyStyles';

const RadioCheckbox = (props) => {
  const { errors } = useFormState();
  const classes = surveyStyles();
  const {
    touched,
    addTouched,
    answersAvailable,
    options,
    random,
    title,
    id,
    index,
    changeQuestionTitle,
    changeQuestionAnswerValue,
    removeQuestionAnswer,
    addQuestionAnswer,
    recordQuestions,
    setRecordQuestions,
  } = props;
  const one = 1;

  const onChangeTitle = (e) => {
    changeQuestionTitle(id, e.target.value);
  };

  const onChangeAnswerValue = (indexN, value) => {
    changeQuestionAnswerValue(id, indexN, value);
  };

  const toggleMultipleValue = (value) => {
    const target = recordQuestions?.find(item => item.id === id);
    const modified = {
      ...target,
      options: {
        isMultiple: value,
      },
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  const setJump = (answerId, value) => {
    const target = recordQuestions?.find(item => item.id === id);
    const { answersAvailable: answersToFind } = target;
    const answerTarget = answersToFind.find(item => item.id === answerId);
    const parsedFind = {
      ...answerTarget,
      jumpToQuestionId: value,
    };
    /* eslint-disable-next-line */
    const parsedAnswers = answersAvailable.map(item => item.id === parsedFind.id ? parsedFind : item);
    const parsedTarget = {
      ...target,
      answersAvailable: parsedAnswers,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === parsedTarget.id ? parsedTarget : item);
    setRecordQuestions(result);
  };

  const getJumpOptions = useMemo(() => {
    const output = [
      { id: undefined, name: 'Don\'t jump' },
      { id: 'end', name: 'End of survey' },
    ];

    const slice = recordQuestions.slice(index);

    slice.map((item) => {
      const parentIndex = recordQuestions.indexOf(item) + one;
      const name = `${parentIndex} - ${item.title}`;
      return output.push({ id: item.id, name });
    });

    return output;
  }, [recordQuestions, index]);

  return (
    <div className={classes.questionListContainer}>
      <div style={{ width: '100%' }}>
        <Accordion style={{ backgroundColor: 'transparent' }}>
          <AccordionSummary
            className={classes.questionListAccordion}
            expandIcon={<ExpandMoreIcon />}
          >
            <FormControlLabel
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={<TextField defaultValue={title} name={`q_${id}_title`} label="Title *" onFocus={() => addTouched(`q_${id}_title`)} onChange={onChangeTitle} fullWidth error={touched?.current?.[`q_${id}_title`] && errors[`q_${id}_title`]} helperText={touched?.current?.[`q_${id}_title`] && errors[`q_${id}_title`]} />}
              label=""
            />
            {errors?.[`q_${id}`] && (
              <div className={classes.questionsErrorWrapper}>
                <ErrorIcon />
                <Typography color="error" variant="caption">{errors?.[`q_${id}`]}</Typography>
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.questionListAnswersWrapper}>
            <Typography variant="h4" color="primary">Answers available</Typography>
            {answersAvailable && answersAvailable.map((answer) => {
              const keyString = `${id}_${answer?.id}`;
              return (
                <div className={classes.answerColumnForIfJump} key={`answer_${keyString}`}>
                  <div className={classes.answersContainer}>
                    <TextField
                      defaultValue={answer?.title}
                      variant="outlined"
                      onFocus={() => addTouched(`q_${id}_a_${answer.id}`)}
                      onChange={e => onChangeAnswerValue(answer?.id, e.target.value)}
                      error={touched?.current?.[`q_${id}_a_${answer.id}`] && errors[`q_${id}_a_${answer.id}`]}
                      helperText={touched?.current?.[`q_${id}_a_${answer.id}`] && errors[`q_${id}_a_${answer.id}`]}
                      fullWidth
                    />
                    <RemoveIcon
                      className={classes.pointer}
                      onClick={() => removeQuestionAnswer(id, answer?.id)}
                    />
                  </div>
                  {!options?.isMultiple && !random && (
                    <div className={classes.answerJumpContainer}>
                      <LastPageIcon />
                      If chosen, jump to:
                      <Select
                        fullWidth
                        name="jump"
                        defaultValue={answer?.jumpToQuestionId || undefined}
                        onChange={e => setJump(answer.id, e.target.value)}
                      >
                        {getJumpOptions.map(item => (
                          <MenuItem divider={item.id === 'end'} value={item.id} key={item.name}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
              );
            })}
            <AddCircleIcon style={{ margin: '0 auto' }} className={classes.pointer} onClick={() => addQuestionAnswer(id)} />
          </AccordionDetails>
        </Accordion>
      </div>
      <FormControlLabel
        control={<Switch checked={options?.isMultiple} name={`multiple_${id}`} onChange={e => toggleMultipleValue(e.target.checked)} />}
        label="Multiple"
      />
    </div>
  );
};

export default RadioCheckbox;
