import React from 'react';
import { useFormState } from 'react-final-form';
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import surveyStyles from '../surveyStyles';
import { inputTypeValues } from '../../../lib/utils/surveys';

const Input = (props) => {
  const { errors } = useFormState();
  const classes = surveyStyles();
  const {
    touched,
    addTouched,
    title,
    id,
    changeQuestionTitle,
    recordQuestions,
    setRecordQuestions,
    type,
  } = props;

  const onChangeTitle = (e) => {
    changeQuestionTitle(id, e.target.value);
  };

  const onChangeInputType = (e) => {
    const target = recordQuestions?.find(item => item.id === id);
    const modified = {
      ...target,
      max: 5000,
      min: 1,
      type: e?.target?.value,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  return (
    <div className={classes.questionInputContainer}>
      <div>
        <TextField
          defaultValue={title}
          name={`q_${id}_title`}
          label="Title *"
          onFocus={() => addTouched(`q_${id}_title`)}
          onChange={onChangeTitle}
          fullWidth
          error={touched?.current?.[`q_${id}_title`] && errors[`q_${id}_title`]}
          helperText={touched?.current?.[`q_${id}_title`] && errors[`q_${id}_title`]}
        />
        {errors?.[`q_${id}`] && (
          <div className={classes.questionsErrorWrapper}>
            <ErrorIcon />
            <Typography color="error" variant="caption">{errors?.[`q_${id}`]}</Typography>
          </div>
        )}
      </div>
      <div>
        <InputLabel error={errors[`q_${id}_type`]}>Answer type *</InputLabel>
        <Select
          fullWidth
          value={type}
          onFocus={() => addTouched(`q_${id}_type`)}
          onChange={onChangeInputType}
          error={errors[`q_${id}_type`]}
        >
          {inputTypeValues.map(value => (
            <MenuItem value={value.id} key={value.name}>{value.name}</MenuItem>
          ))}
        </Select>
        {errors?.[`q_${id}_type`] && (
          <div className={classes.errorLittleWrapper}>
            <ErrorIcon />
            <Typography color="error" variant="caption">{errors?.[`q_${id}_type`]}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
