import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  Edit,
  useEditController,
  Loading,
  useNotify,
  useDataProvider,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { useRefresh } from 'ra-core';
import 'suneditor/dist/css/suneditor.min.css';

import StaticForm from './StaticForm';
import { RADIX_TEN } from '../promotions/constants';

const StaticEdit = ({ permissions, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { record, loading, saving } = useEditController(props);
  const [contentDesktop, setContentDesktop] = useState('');
  const [contentMobile, setContentMobile] = useState('');
  const [splitContents, setSplitContents] = useState(false);

  const StaticToolBar = staticProps => (
    <Toolbar {...staticProps}>
      <SaveButton disabled={loading || saving} />
    </Toolbar>
  );

  const onSuccessActions = () => {
    notify('Content updated successfully', 'success');
    refresh();
  };

  useEffect(() => {
    if (record) {
      setContentDesktop(record?.desktopContent || '');
      setContentMobile(record?.mobileContent || '');

      const areTheSameContent = record?.desktopContent === record?.mobileContent;

      setSplitContents(!areTheSameContent);
    }
  }, [record]);

  const handleSave = (values) => {
    const blobDesktop = new Blob([contentDesktop], { encoding: 'UTF-8', type: 'text/plain;charset=UTF-8' });
    const blobMobile = splitContents
      ? new Blob([contentMobile], { encoding: 'UTF-8', type: 'text/plain;charset=UTF-8' })
      : blobDesktop;

    dataProvider.update('staticContent', {
      id: record?.id,
      input: {
        alias: values.alias,
        desktopContent: blobDesktop,
        mobileContent: blobMobile,
        name: values.name,
        position: parseInt(values.position, RADIX_TEN),
        status: values.status,
      },
    })
      .then(onSuccessActions)
      .catch(err => notify(err?.message, 'error'));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Edit {...props} title={`Statics: ${record.name}`}>
      <SimpleForm save={handleSave} toolbar={<StaticToolBar />}>
        <StaticForm
          splitContents={splitContents}
          setSplitContents={setSplitContents}
          contentDesktop={contentDesktop}
          setContentDesktop={setContentDesktop}
          contentMobile={contentMobile}
          setContentMobile={setContentMobile}
          {...props}
        />
      </SimpleForm>
    </Edit>
  );
};


export default StaticEdit;
