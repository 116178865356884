import React, { useMemo, useState } from 'react';
import { Button, Toolbar, useNotify } from 'react-admin';
import { useLoading } from 'ra-core/lib';
import { CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';

import { promotionFormStyles } from './promotionFormStyles';
import { parsePromotionUpdateInputToValidate, parseValidateResponse } from './PromotionUtils';
import validateInput from '../../lib/utils/validateInput';

const PromotionToolbar = (promProps) => {
  const { childProcessing, recordValues, handleSubmit } = promProps;
  const [loading, setLoading] = useState(false);
  const isLoading = useLoading();
  const notify = useNotify();
  const classes = promotionFormStyles();

  const onSubmit = async () => {
    setLoading(true);
    const input = parsePromotionUpdateInputToValidate(recordValues);
    const validateResponse = await validateInput(input, 'updatePromotion');
    setLoading(false);

    if (_.has(validateResponse, 'data')) {
      const { data } = validateResponse;
      const { validateInputPromotionService } = data;

      if (validateInputPromotionService === null || validateInputPromotionService === '[]') {
        handleSubmit();
      } else {
        const parsed = JSON.parse(validateInputPromotionService);
        const msg = parseValidateResponse(parsed);
        notify(msg, 'error', {}, false, false, true);
      }
    } else {
      notify('GraphQL error trying to validate', 'error');
    }
  };

  const isDisabled = useMemo(
    () => loading || isLoading || childProcessing,
    [loading, isLoading, childProcessing],
  );

  return (
    <Toolbar {...promProps} className={classes.fixedSaveButton}>
      <Button
        variant="contained"
        label="Validate & Save"
        size="large"
        startIcon={isDisabled ? <CircularProgress size={22} /> : <SaveIcon />}
        disabled={isDisabled}
        onClick={onSubmit}
      />
    </Toolbar>
  );
};

export default PromotionToolbar;
