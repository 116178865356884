import React, { cloneElement, useState } from 'react';
import {
  Edit, Loading,
  SimpleForm, useEditController,
  useNotify, useTranslate,
} from 'react-admin';
import { useRefresh } from 'ra-core';
import Card from '@material-ui/core/Card';
import './tab.css';

import Form from './Form';
import SurveyToolbar from './SurveyToolbar';
import { validateSurveyForm } from '../../lib/utils/surveys';
import { promotionFormStyles } from '../promotions/promotionFormStyles';
import { Tab, TabbedLayout } from '../../lib/components';
import SegmentationForm from '../../lib/components/SegmentationForm';
import SurveyRouter from './SurveyRouter';

const DETAIL = 'DETAIL';
const SEGMENTATION = 'SEGMENTATION';
const ROUTER = 'ROUTER';
let recordData;

const CustomEdit = (props) => {
  const {
    record,
    ...rest
  } = useEditController(props);
  const [selectedTab, setSelectedTab] = useState(DETAIL);
  const { children, input, setInput } = props;
  const translate = useTranslate();
  const classes = promotionFormStyles();
  recordData = record;
  return (
    <div className={classes.root}>
      <TabbedLayout>
        <Tab
          label={translate('surveys.tabs.detail')}
          onClick={() => {
            if (selectedTab !== DETAIL) {
              setSelectedTab(DETAIL);
            }
          }}
        >
          <Card>
            {cloneElement(children, {
              record,
              ...rest,
            })}
          </Card>
        </Tab>
        <Tab
          label={translate('surveys.tabs.segmentation')}
          path="segmentation"
          onClick={() => {
            setSelectedTab(SEGMENTATION);
          }}
        >
          {
            !recordData && <Loading />
          }
          {recordData && recordData.id
            && <SegmentationForm surveyId={recordData.id} />
          }
        </Tab>
        <Tab
          label={translate('surveys.tabs.router')}
          path="router"
          onClick={() => {
            setSelectedTab(ROUTER);
          }}
          className={`tab-type-${recordData?.flow?.toLowerCase()}`}
        >
          {
            !recordData && <Loading />
          }
          {recordData && recordData.id
            && <SurveyRouter input={input} setInput={setInput} recordData={recordData} />
          }
        </Tab>
      </TabbedLayout>
    </div>
  );
};

const SurveyEdit = (props) => {
  const notify = useNotify();
  const [input, setInput] = useState(undefined);
  const refresh = useRefresh();
  const zero = 0;

  const onSuccess = () => {
    notify('surveys.edit.updated', 'success');
    refresh();
    window.scrollTo({ top: zero, behavior: 'smooth' });
  };

  const validate = () => validateSurveyForm(input);

  return (
    <CustomEdit {...props} input={input} setInput={setInput} undoable={false}>
      <Edit
        {...props}
        onSuccess={onSuccess}
        actions={null}
        resource="surveys"
        mutationMode="pessimistic"
      >
        <SimpleForm
          redirect="edit"
          toolbar={<SurveyToolbar isEdit input={input} />}
          validate={validate}
          validateOnBlur
        >
          <Form isEdit setInput={setInput} {...props} />
        </SimpleForm>
      </Edit>
    </CustomEdit>
  );
};

export default SurveyEdit;
