import React, { useState, useEffect } from 'react';
import {
  Admin,
  Resource,
} from 'react-admin';
import { useClearCache } from 'react-clear-cache';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import authProvider from './providers/authProvider';
import dataProviderFactory from './providers/providerFactory';
import Layout from './layout/Layout/Layout';
import Login from './layout/Login/Login';
import customRoutes from './routes';
import users from './resources/users';
import promotions from './resources/promotions';
import orders from './resources/orders';
import comments from './resources/comments';
import segmentations from './resources/segmentations';
import products from './resources/products';
import pickupPoints from './resources/pickupPoints';
import staticContent from './resources/staticContent';
import surveys from './resources/surveys';
import themeMaterial from './themes/themeMaterial';
import messages from './i18n';

const i18nProvider = polyglotI18nProvider(
  locale => (messages[locale] ? messages[locale] : messages.en),
  'en',
  { allowMissing: true },
);


const useStyles = makeStyles(theme => ({
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAling: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(),
    },
  },
}));

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const classes = useStyles();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    const fetchDataProvider = async () => {
      const dataProviderInstance = await dataProviderFactory(
        'graphql',
      );
      setDataProvider(
        () => dataProviderInstance,
      );
    };
    fetchDataProvider();
  }, []);

  if (!isLatestVersion) {
    return emptyCacheStorage();
  }

  if (!dataProvider) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
        <div className="loader">Loading...</div>
      </div>
    );
  }
  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      title="SAZ Admin"
      layout={Layout}
      loginPage={Login}
      theme={themeMaterial}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      disableTelemetry
    >
      {permissions => [
        permissions ? <Resource name="users" {...users} /> : null,
        permissions ? <Resource name="products" {...products} /> : null,
        permissions ? <Resource name="promotions" {...promotions} /> : null,
        permissions ? <Resource name="pickupPoints" {...pickupPoints} /> : null,
        permissions ? <Resource name="orders" {...orders} /> : null,
        permissions ? <Resource name="segmentations" {...segmentations} /> : null,
        permissions ? <Resource name="surveys" {...surveys} /> : null,
        permissions ? <Resource name="comments" {...comments} /> : null,
        permissions ? <Resource name="productImage" /> : null,
        permissions ? <Resource name="staticContent" options={{ label: 'Static Content' }} {...staticContent} /> : null,
      ]}
    </Admin>
  );
};


export default App;
